import React from 'react';
import clsx from 'clsx';

export interface RadioPropsOption<
  T = string | number | readonly string[] | undefined,
> {
  label: string | React.ReactElement;
  name: string;
  value: T;
  subLabel?: string;
  defaultChecked?: boolean;
  className?: string;
  disabled?: boolean;
}

interface RadioProps {
  className?: string;
  errorMessage?: string | JSX.Element;
  options: RadioPropsOption[];
  flipLabels?: boolean;
}

const BaseRadio = (
  { className, options, errorMessage, flipLabels }: RadioProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element => (
  <div className={className}>
    {options.map(
      ({
        label,
        name,
        value,
        subLabel,
        defaultChecked,
        className: optionClassName,
        disabled,
      }) => (
        <label
          key={`${name}-${value}`}
          htmlFor={`${name}-${value}`}
          className={clsx(
            'flex gap-2 items-center',
            {
              'cursor-pointer': !disabled,
              'flex-row-reverse': flipLabels,
              'justify-between': flipLabels,
            },
            optionClassName,
          )}
        >
          <input
            ref={ref}
            id={`${name}-${value}`}
            name={name}
            value={value}
            type="radio"
            disabled={disabled}
            defaultChecked={defaultChecked}
            className="cursor-pointer"
          />

          <div className="flex flex-col">
            <p
              className={clsx({
                'text-slate-800': !disabled,
                'text-slate-600': disabled,
              })}
            >
              {label}
            </p>

            {subLabel && <p className="text-slate-600 text-sm">{subLabel}</p>}
          </div>
        </label>
      ),
    )}

    {errorMessage && (
      <div className="mt-2 text-sm text-red-500">{errorMessage}</div>
    )}
  </div>
);

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(BaseRadio);
